<template>
	<div class="detali">
		<div class="content">
			<div style="background: #ffffff">
				<div class="title">
					<i class="title-tag"> </i>
					简介
				</div>
				<div class="detali-page1">
					<div class="between">
						<div class="left">
							<div class="titles">
								<img :src="detalis.image" />
							</div>
						</div>

						<div class="grow-1">
							{{detalis.user_name}}
							<div class="money" v-html="detalis.content"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="detali-page2">
				<div class="title">
					<i class="title-tag"> </i>
					工作经历
				</div>
				<div class="content">
					<div class="pages2-top" v-for="(item,index) in weekList " :key="index" style="background-color: rgb(250, 250, 250);margin-top: 15px;">
						<div class="pages-top-left" style="margin-right:110px;width: 20px;">
							<img style="width: 110px;height:110px;" :src="item.image"/>
						</div>
						<div style="width:70%;">
							<div style="font-weight: 700;font-size: 19px;margin-bottom: 15px;">
								{{item.title}}
							</div>
							<div style="font-weight: 100;font-size: 14px;white-space:normal;word-break:break-all;">
								{{item.content}}
							</div>
						</div>
					</div>
					
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "school_detail",
				id: this.$route.query.id,
			});
			this.detalis = data.arr;
			var pic_content = data.arr.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
			this.detalis.content = pic_content;
			this.weekList=data.arr.workList;
		},
		data() {
			return {
				detalis: {
					headimg:'http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/nick_img.png" style="width: 193px;height:193px;border-radius:50%;',
					top_star_time: "",
					top_end_time: "",
					star_time: "",
					end_time: "",
				},
				weekList:[
					{
						title:'工作经历 | 工作经历',
						image:'http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/tc_01.png',
						content:'工作简介工作简介工作简介工作简介工作简介工作简介工作简介',
					},
					{
						title:'工作经历 | 工作经历',
						image:'http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/tc_02.png',
						content:'工作简介工作简介工作简介工作简介工作简介工作简介工作简介',
					},
					{
						title:'工作经历 | 工作经历',
						image:'http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/tc_03.png',
						content:'工作简介工作简介工作简介工作简介工作简介工作简介工作简介',
					},
				]
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title {
		display: flex;
		padding: 14px 20px;
		border-bottom: 1px solid #dfdfdf;

		.title-tag {
			width: 4px;
			height: 20px;
			background: rgb(0, 118, 254);
			margin-right: 7px;
		}
	}

	.detali {
		background: rgb(248, 248, 248);
		height: 100%;
		padding: 23px 0px 50px;

		.content {
			width: 1200px;
			margin: 0 auto;
		}
	}

	.detali-page1 {
		padding: 48px 48px 60px;
		margin-bottom: 25px;

		.time {
			margin-top: 11px;
			background: rgb(246, 246, 246);
			padding: 20px;
			padding-left: 16px;
		}

		.grow-1 {
			width: 70%;
			margin-top: -140px;
			margin-left: 20px;
			font-weight: 600;
			white-space:normal;
			word-break:break-all;
		}

		.money {
			font-size: 15px;
			margin-top: 16px;
			font-weight: 100;
			

		}

		.status {
			padding: 6px 36px;
			color: rgb(0, 118, 254);
			border: 1px solid rgb(0, 118, 254);
		}

		.timeText {
			font-size: 10px;
			color: #676767;
			margin-top: 5px;
		}

		.left {
			width: 20%;
		}

		.titles {
			font-size: 12px;
		}
	}

	.detali-page2 {
		background: #ffffff;

		.content {
			padding: 30px 38px;
		}

		.pages2-top {
			padding: 10px 27px;
			display: flex;

			.pages-top-left {
				color: #676767;
				margin-right: 36px;
			}
		}

		.contents {
			white-space: pre-wrap;
			color: #676767;
			line-height: 26px;
			font-size: 13px;
			padding: 41px 0px 200px;
		}
	}
</style>
